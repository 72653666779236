<template>
  <v-row class="justify-center">
    <v-col>
      <div class="text-center" v-if="patient.history.length === 0">
        <p class="display-1 font-weight-thin">Pas d'antécédent</p>
          <v-btn small @click="dialog.antecedent = true" color="purple" outlined>
          <v-icon>mdi-plus</v-icon> Ajouter un antécédent
        </v-btn>
      </div>
      <div v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Antécédents / Pathologies associées</th>
                <th class="text-center">Début</th>
                <th class="text-center">Toujours présent</th>
                <th class="text-center">Traitement associé</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in patient.history" :key="i">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-center">{{ convertDate(item.start) }}</td>
                <td class="text-center"><v-icon small v-text="icon(item.still)"></v-icon></td>
                <td class="text-center"><v-icon small v-text="icon(item.treatment)"></v-icon></td>
                <td class="text-center">
                  <v-btn small icon color="red" @click="remove(i)" outlined>
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row class="mt-5">
          <v-spacer></v-spacer>
          <v-btn small class="d-flex d-inline" @click="dialog.antecedent = true"
          color="purple" outlined>
          <v-icon>mdi-plus</v-icon> Ajouter un antécédent
        </v-btn>
      </v-row>
    </div>

    <v-dialog v-model="dialog.antecedent" max-width="35%">
      <v-card>
        <v-form ref="form" v-on:submit.prevent="add()">
          <v-card-title class="headline">Ajouter un antécédent</v-card-title>
          <v-card-text>
            <v-text-field
            prepend-icon="mdi-hospital"
            type="text"
            :counter="64"
            :rules="rules.basic"
            v-model="antecedent.name"/>
            <v-menu
            ref="menu_history"
            v-model="menuHistory"
            :close-on-content-click="true"
            :return-value.sync="antecedent.start"
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="antecedent.start"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on" >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="antecedent.start" no-title scrollable
            locale="fr-fr"
            @change="$refs.menu_history.save(antecedent.start)">
            <v-btn text color="primary" @click="menuHistory = false">Cancel</v-btn>
            <v-btn text color="primary"
            @click="$refs.menu_history.save(antecedent.start)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      <p>Toujours présent ?</p>
      <v-radio-group v-model="antecedent.still" row>
        <v-radio label="Oui" value="yes"></v-radio>
        <v-radio label="Non" value="no"></v-radio>
        <v-radio label="Ne sait pas" value="nc"></v-radio>
      </v-radio-group>
      <p>Traitement associé ?</p>
      <v-radio-group v-model="antecedent.treatment" row>
        <v-radio label="Oui" value="yes"></v-radio>
        <v-radio label="Non" value="no"></v-radio>
        <v-radio label="Ne sait pas" value="nc"></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="dialog.antecedent = !dialog.antecedent">
        Annuler
      </v-btn>
      <v-btn type="submit" color="green darken-1" outlined>Ajouter</v-btn>
    </v-card-actions>
    </v-form>
    </v-card>
  </v-dialog>
</v-col>
</v-row>
</template>

<script>
import MixinPatients from '@/mixins/patients';

export default {
  name: 'Antecedents',
  mixins: [MixinPatients],
  props: { patient: Object },
  data: () => ({
    formIsValid: true,
    menuHistory: false,
    dialog: {
      antecedent: false,
    },
    antecedent: {
      name: 'Description...',
      start: new Date().toISOString().slice(0, 10),
      still: 'nc',
      treatment: 'nc',
    },
  }),

  methods: {
    icon(e) {
      if (e === 'yes') return 'mdi-check';
      if (e === 'no') return 'mdi-minus';
      return 'mdi-help';
    },
    add() {
      this.patient.history.push({ ...this.antecedent });
      this.antecedent.name = 'Description...';
      this.antecedent.start = new Date().toISOString().slice(0, 10);
      this.antecedent.still = 'non';
      this.antecedent.treatment = 'non';
      this.dialog.antecedent = false;

      if (this.$refs.form.validate()) {
        this.$emit('update-patient', { type: 'history', data: this.patient.history });
      }
    },

    remove(index) {
      this.patient.history.splice(index, 1);
      this.$emit('update-patient', { type: 'history', data: this.patient.history });
    },
  },
};
</script>
