<template>
  <v-row class="flex-row align-self-stretch">
    <slot/>
  </v-row>
</template>

<script>
export default {
  name: 'UpdateForm',
};
</script>
