<template>
  <v-row class="justify-center">
    <v-col>
      <div class="text-center" v-if="patient.treatments.length === 0">
        <p class="display-1 font-weight-thin">Aucun traitement</p>
          <v-btn small @click="dialog.treatments = true" color="purple" outlined>
          <v-icon>mdi-plus</v-icon> Ajouter un traitemnt
        </v-btn>
      </div>
      <div v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nom</th>
                <th class="text-center">Indication</th>
                <th class="text-center">Posologie</th>
                <th class="text-center">Début</th>
                <th class="text-center">Fin</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in patient.treatments" :key="i">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-center">{{ item.indication }}</td>
                <td class="text-center">{{ item.dosage }}</td>
                <td class="text-center">{{ convertDate(item.start) }}</td>
                <td class="text-center">{{ convertDate(item.end) }}</td>
                <td class="text-center">
                  <v-btn small icon color="red" @click="remove(i)" outlined>
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row class="mt-5">
          <v-spacer></v-spacer>
          <v-btn small class="d-flex d-inline" @click="dialog.treatments = true"
          color="purple" outlined>
          <v-icon>mdi-plus</v-icon> Ajouter un traitement
        </v-btn>
      </v-row>
    </div>

    <v-dialog v-model="dialog.treatments" max-width="35%">
      <v-card>
        <v-form ref="form" v-on:submit.prevent="add()">
          <v-card-title class="headline">Ajouter un traitement</v-card-title>
          <v-card-text>
            <v-text-field
            prepend-icon="mdi-pill"
            type="text"
            :counter="64"
            :rules="rules.basic"
            v-model="treatment.name"/>
            <v-text-field
            prepend-icon="mdi-hand-pointing-right"
            type="text"
            :counter="64"
            :rules="rules.basic"
            v-model="treatment.indication"/>
            <v-text-field
            prepend-icon="mdi-beaker"
            type="text"
            :counter="64"
            :rules="rules.basic"
            v-model="treatment.dosage"/>
            <v-menu
            ref="menu_treatment_start"
            v-model="menuTreatmentStart"
            :close-on-content-click="true"
            :return-value.sync="treatment.start"
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="treatment.start"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on" >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="treatment.start" no-title scrollable
            locale="fr-fr"
            @change="$refs.menu_treatment_start.save(treatment.start)">
            <v-btn text color="primary" @click="menuTreatmentStart = false">Annuler</v-btn>
            <v-btn text color="primary"
            @click="$refs.menu_history.save(antecedent.start)">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-menu
        ref="menu_treatment_end"
        v-model="menuTreatmentEnd"
        :close-on-content-click="true"
        :return-value.sync="treatment.end"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          v-model="treatment.end"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on" >
        </v-text-field>
      </template>
      <v-date-picker
        v-model="treatment.end" no-title scrollable
        locale="fr-fr"
        @change="$refs.menu_treatment_end.save(treatment.end)">
        <v-btn text color="primary" @click="menuTreatmentEnd = false">Annuler</v-btn>
        <v-btn text color="primary"
        @click="$refs.menu_history.save(antecedent.end)">OK</v-btn>
      </v-date-picker>
    </v-menu>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="dialog.treatments = !dialog.treatments">
        Annuler
      </v-btn>
      <v-btn type="submit" color="green darken-1" outlined>Ajouter</v-btn>
    </v-card-actions>
    </v-form>
    </v-card>
  </v-dialog>
</v-col>
</v-row>
</template>

<script>
import MixinPatients from '@/mixins/patients';

export default {
  name: 'Treatments',
  mixins: [MixinPatients],
  props: { patient: Object },
  data: () => ({
    menuTreatmentEnd: false,
    menuTreatmentStart: false,
    dialog: {
      treatments: false,
    },
    treatment: {
      name: 'Nom commercial',
      indication: 'Dépression',
      dosage: '3 fois/jour',
      start: new Date().toISOString().slice(0, 10),
      end: '',
    },
  }),

  methods: {
    add() {
      this.patient.treatments.push({ ...this.treatment });
      this.treatment.name = 'Nom commercial';
      this.treatment.indication = 'Dépression';
      this.treatment.dosage = '3 fois/jour';
      this.treatment.start = new Date().toISOString().slice(0, 10);
      this.treatment.end = '';
      this.dialog.treatments = false;

      if (this.$refs.form.validate()) {
        this.$emit('update-patient', { type: 'treatments', data: this.patient.treatments });
      }
    },

    remove(index) {
      this.patient.treatments.splice(index, 1);
      this.$emit('update-patient', { type: 'treatments', data: this.patient.treatments });
    },
  },
};
</script>
