<template>
  <v-row class="justify-center">
    <v-col cols="6">
      <p class="title">Mise à jour des informations de l'entretien</p>
      <v-col>
        <FormAlert :info="{alert}"></FormAlert>
      </v-col>
      <v-form ref="form" v-model="formIsValid" v-on:submit.prevent="update()">
        <v-text-field
          label="Nom"
          name="name"
          prepend-icon="mdi-clipboard-text"
          type="text"
          :counter="64"
          :rules="this.rules.name"
          v-model="interview.name"/>
          <v-textarea label="Commentaires"
          :value="interview.comment" :counter="1024"></v-textarea>
          <v-row>
            <v-col class="text-right">
            <v-spacer></v-spacer><br/>
            <v-btn class="ml-2" color="red" text @click="$router.go(-1);">Annuler</v-btn>
            <v-btn class="ml-2" type="submit" :disabled="!formIsValid" color="green darken-1"
              outlined>Mettre à jour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'InterviewUpdateForm',
  mixins: [MixinInterviews],
  components: { FormAlert },
  props: {
    id: String,
    patientId: String,
  },
  data: () => ({
    formIsValid: true,
    interview: { name: '' },
  }),

  async mounted() {
    this.interview = await this.getInterviewInfo(this.patientId, this.id);
  },

  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        await this.updateInterview(this.id, this.patientId, this.interview);
      }
    },
  },
};
</script>
