<template>
  <v-row class="flex-row align-self-stretch">
    <UpdateForm v-if="type === 'patient'">
      <PatientUpdateForm :id="this.$route.params.patientId"/>
    </UpdateForm>
    <UpdateForm v-else-if="type === 'interview'">
      <InterviewUpdateForm
        :id="this.$route.params.interviewId"
        :patientId="this.$route.params.patientId"
      />
    </UpdateForm>
    <UpdateForm v-else>
      <UserUpdateForm/>
    </UpdateForm>
  </v-row>
</template>

<script>
import MixinUsers from '@/mixins/users';
import UpdateForm from '@/components/common/forms/Update.vue';
import UserUpdateForm from '@/components/user/Update.vue';
import PatientUpdateForm from '@/components/patients/Update.vue';
import InterviewUpdateForm from '@/components/interviews/Update.vue';

export default {
  name: 'Update',
  components: {
    UpdateForm,
    UserUpdateForm,
    PatientUpdateForm,
    InterviewUpdateForm,
  },
  mixins: [MixinUsers],
  props: {
    type: String,
  },
};
</script>
