<template>
  <v-row class="justify-center">
    <v-col cols="6">
      <p class="title">Mise à jour de mes informations</p>
      <v-col>
        <FormAlert :info="{alert}"></FormAlert>
      </v-col>
      <v-form ref="form" v-on:submit.prevent="update()" v-model="formIsValid">
        <v-text-field label="Prénom et nom" name="name" prepend-icon="mdi-account" type="text"
        :counter="64" :rules="this.rules.name" v-model="user.name">
      </v-text-field>

      <v-text-field label="Email" name="email" prepend-icon="mdi-at" type="text"
      :rules="this.rules.email" v-model="user.email">
    </v-text-field>

    <v-text-field id="password" label="Mot de passe" name="password" prepend-icon="mdi-lock"
    :append-icon="passwordIsVisible ? 'mdi-eye' : 'mdi-eye-off'" :rules="this.rules.password"
    :type="passwordIsVisible ? 'text' : 'password'" v-model="user.password"
    @click:append="passwordIsVisible = !passwordIsVisible">
  </v-text-field>

  <div class="text-left">
    <v-btn small color="red lighten-2" @click="show.confirmation = true" text>
      <v-icon small>mdi-delete</v-icon> Je souhaite supprimer mon compte
    </v-btn>
  </div>
  <br/>
  <v-row>
    <br/>
    <v-spacer></v-spacer>
    <v-btn class="ml-2" color="red"  @click="$router.go(-1)" text>Annuler</v-btn>
    <v-btn class="ml-2" type="submit" :disabled="!formIsValid" color="green darken-1" outlined>
      Mettre à jour
    </v-btn>
  </v-row>
</v-form>
<v-dialog v-model="show.confirmation" persistent max-width="35%">
  <v-card>
    <v-card-title class="headline">Confirmation</v-card-title>
    <v-card-text>
      <p>Le compte associé à l'adresse <b>{{ user.email }}</b>va être définitivement supprimé.</p>
      <p>Êtes-vous sûr de vouloir continuer ?</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="show.confirmation = false;">Annuler</v-btn>
      <v-btn color="red" outlined @click="deleteUser();">Supprimer mon compte</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-col>
</v-row>
</template>

<script>
import MixinUsers from '@/mixins/users';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'UserUpdateForm',
  mixins: [MixinUsers],
  components: { FormAlert },
  data: () => ({
    formIsValid: true,
    passwordIsVisible: false,
    show: { confirmation: false },
    user: { name: '', email: '', password: '' },
  }),

  async mounted() {
    this.user = await this.getUserInfo();
    this.user.password = '';
  },
  methods: {
    // Update current user information
    async update() {
      if (this.$refs.form.validate()) {
        this.updateUser(JSON.stringify(this.user));
      }
    },
  },
};
</script>
