<template>
  <v-row class="justify-center">
    <v-col cols="6">
      <p class="display-1">Mise à jour des informations du patient</p>
      <FormAlert :info="{alert}"></FormAlert>

      <p class="title">Informations personnelles</p>
      <Personal :patient="{...patient}"/>

      <p class="title">Antécédents</p>
      <Antecedents @update-patient="update" :patient="{...patient}"/>

      <p class="title">Traitement concomitants</p>
      <Treatments @update-patient="update" :patient="{...patient}"/>
    </v-col>
  </v-row>
</template>

<script>
import MixinPatients from '@/mixins/patients';
import FormAlert from '@/components/common/forms/Alert.vue';
import Personal from '@/components/patients/forms/Personal.vue';
import Antecedents from '@/components/patients/forms/Antecedents.vue';
import Treatments from '@/components/patients/forms/Treatments.vue';

export default {
  name: 'PatientUpdateForm',
  mixins: [MixinPatients],
  components: {
    FormAlert,
    Personal,
    Antecedents,
    Treatments,
  },
  props: { id: String },
  data: () => ({
    patient: {
      name: '',
      email: '',
      initials: '',
      number: 0,
      birth: null,
      inclusion: null,
      city: '',
      gender: '',
      path: '',
      lifestyle: '',
      profession: '',
      history: [],
      treatments: [],
      iq: 0,
    },
  }),

  async mounted() {
    this.patient = await this.getPatientInfo(this.id);
    this.patient.id = this.id;

    if (this.patient.number) {
      this.patient.number = this.patient.number.toString();
    }

    if (this.patient.birth) {
      const [birthDate] = new Date(this.patient.birth).toISOString().split('T');
      this.patient.birth = birthDate;
    }

    if (this.patient.inclusion) {
      const [inclusionDate] = new Date(this.patient.inclusion).toISOString().split('T');
      this.patient.inclusion = inclusionDate;
    }

    if (this.patient.history) {
      this.patient.history = this.patient.history.map((x) => ({
        name: x.name,
        start: new Date(x.start).toISOString().split('T')[0],
        still: x.still,
        treatment: x.treatment,
      }));
    }

    if (this.patient.treatments) {
      this.patient.treatments = this.patient.treatments.map((x) => ({
        name: x.name,
        dosage: x.dosage,
        indication: x.indication,
        start: new Date(x.start).toISOString().split('T')[0],
        end: x.end ? new Date(x.end).toISOString().split('T')[0] : '',
      }));
    }
  },

  methods: {
    async update(e = null) {
      // e.type = 'history' or 'treatment'
      if (e.type === 'history') {
        this.patient.history = e.data;
      } else if (e.type === 'treatments') {
        this.patient.treatments = e.data;
      }

      if (this.patient.number) {
        this.patient.number = this.patient.number.toString().padStart(3, '0');
      }
      await this.updatePatient(this.id, this.patient);
    },
  },
};
</script>
