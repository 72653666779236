<template>
  <v-row class="justify-center">
    <v-col>
      <v-snackbar top v-model="confirm" :timeout="3000">Informations enregistrées
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="confirm = false">
          <v-icon color="white">mdi-close</v-icon></v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" v-model="formIsValid" v-on:submit.prevent="update()">
        <v-text-field
          label="Prénom et nom"
          name="name"
          prepend-icon="mdi-account"
          type="text"
          :counter="64"
          :rules="this.rules.name"
          v-model="patient.name"/>
        <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-at"
          type="text"
          v-model="patient.email"
          :rules="this.rules.email"/>
          <v-row>
            <v-col>
              <v-text-field
                label="Initiales du sujet"
                name="initials"
                prepend-icon="mdi-alphabetical"
                type="text"
                :counter="3"
                :rules="this.rules.initials"
                v-model="patient.initials"/>
            </v-col>
            <v-col>
              <v-text-field
              label="Numéro du sujet"
              name="number"
              prepend-icon="mdi-numeric"
              type="number"
              :counter="3"
              :rules="this.rules.number"
              v-model="patient.number"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                close-on-content-click
                :return-value.sync="patient.birth"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="patient.birth"
                    name="birth"
                    label="Date de naissance"
                    prepend-icon="mdi-cake-variant"
                    readonly
                    v-bind="attrs"
                    v-on="on" >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="patient.birth" no-title scrollable
                  locale="fr-fr"
                  :max="nowDate"
                  @change="$refs.menu.save(patient.birth)">
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(patient.birth)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menu2"
                v-model="menu2"
                close-on-content-click
                :return-value.sync="patient.inclusion"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="patient.inclusion"
                    name="inclusion"
                    label="Date d'inclusion"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on" >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="patient.inclusion" no-title scrollable
                  locale="fr-fr" @change="$refs.menu2.save(patient.inclusion)">
                  <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(patient.inclusion)">OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Commune de naissance"
                name="city"
                prepend-icon="mdi-map-marker"
                type="text"
                :counter="64"
                :rules="this.rules.city"
                v-model="patient.city"/>
            </v-col>
            <v-col>
              <v-select
                :items="gender"
                item-text="value"
                item-value="id"
                label="Sexe"
                name="gender"
                prepend-icon="mdi-gender-transgender"
                v-model="patient.gender">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="path"
                item-text="value"
                item-value="id"
                label="Niveau scolaire/parcours"
                name="path"
                prepend-icon="mdi-school"
                v-model="patient.path">
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :items="lifestyle"
                item-text="value"
                item-value="id"
                label="Mode de vie"
                name="lifestyle"
                prepend-icon="mdi-human-male-female"
                v-model="patient.lifestyle">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Profession/Source de revenus"
                name="profession"
                prepend-icon="mdi-briefcase"
                type="text"
                :counter="64"
                :rules="this.rules.profession"
                v-model="patient.profession"/>
              </v-col>
              <v-col>
                <v-select
                  v-model="patient.drugs"
                  :items="drugs"
                  item-text="value"
                  item-value="id"
                  attach
                  chips
                  prepend-icon="mdi-beaker-outline"
                  label="Consommation de substances"
                  multiple
          ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="patient.livingPlace"
                  :items="livingPlace"
                  item-text="value"
                  item-value="id"
                  attach
                  prepend-icon="mdi-home-variant"
                  label="Lieu de vie"
          ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                label="Q.I"
                name="number"
                prepend-icon="mdi-brain"
                type="number"
                :rules="this.rules.number"
                v-model="patient.iq"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea counter label="Autres informations"
                v-model="patient.other"
                :value="patient.other">
                </v-textarea>
              </v-col>
            </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="ml-2" color="red" text to="/profile">Annuler</v-btn>
            <v-btn class="ml-2" type="submit" :disabled="!formIsValid" color="green darken-1"
              outlined>
              Mettre à jour
            </v-btn>
          </v-row>
          </v-form>
        </v-col>
      </v-row>
    </template>
<script>
import MixinPatients from '@/mixins/patients';

export default {
  name: 'Personnal',
  mixins: [MixinPatients],
  props: { patient: Object },
  data: () => ({
    formIsValid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    date: null,
    menu: false,
    menu2: false,
    confirm: false,
  }),

  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        const data = { ...this.patient };
        if (this.patient.number) {
          data.number = this.patient.number.toString().padStart(3, '0');
        }
        await this.updatePatient(this.patient.id, data);
        // FIXME: a mettre dans le snackbar global. Pb d'event dans les slots
        this.confirm = true;
      }
    },
  },
};
</script>
